<template>
  <div>
    <search-bar @search-value="search($event)" />
    <table class="table-d">
      <thead class="table-d__thead">
        <tr>
          <th v-for="(header, index) in headers" :key="'header' + index">
            {{ header }}
          </th>
          <th v-if="adminCategory === 'edition'">Excel</th>
          <th v-if="adminCategory === 'edition'">Wgrane pliki</th>
          <th v-if="adminCategory === 'edition'">Pobierz ogłoszenia</th>
          <th v-if="adminCategory === 'edition'">Wizualizacje</th>
          <th v-if="adminCategory === 'company'">Podejrzyj</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="table-d__tbody">
        <tr v-for="(data, index) in searched" :key="index">
          <td v-for="(header, index) in headers" :key="data.id + ' ' + index">
            <span
              v-if="header.toLowerCase() === 'treść'"
              v-html="returnObjectValue(data, index)"
            >
            </span>
            <ul
              class="list-padding"
              v-else-if="
                header.toLowerCase() === 'firma' &&
                Array.isArray(returnObjectValue(data, index))
              "
            >
              <li
                v-for="item in returnObjectValue(data, index)"
                :key="item + '_single_company'"
              >
                {{ item }}
              </li>
            </ul>
            <span v-else>
              {{ returnObjectValue(data, index) }}
            </span>
          </td>
          <td v-if="adminCategory === 'edition'">
            <button v-show="!excelUrl[data.id]" @click="downloadExcel(data.id)">
              Wygeneruj
            </button>
            <a download :href="excelUrl[data.id]" v-show="excelUrl[data.id]">
              <span>Pobierz</span>
            </a>
          </td>
          <td v-if="adminCategory === 'edition'">
            <button v-show="!filesUrl[data.id]" @click="downloadFiles(data.id)">
              Wygeneruj
            </button>
            <a download :href="filesUrl[data.id]" v-show="filesUrl[data.id]">
              <span>Pobierz</span>
            </a>
          </td>
          <td v-if="adminCategory === 'edition'">
            <button
              v-show="!advertUrl[data.id]"
              @click="downloadAdvertisements(data.id)"
            >
              Wygeneruj
            </button>
            <a download :href="advertUrl[data.id]" v-show="advertUrl[data.id]">
              <span>Pobierz</span>
            </a>
          </td>
          <td v-if="adminCategory === 'edition'">
            <button
              v-show="!visualisationUrl[data.id]"
              @click="downloadVisualisation(data.id)"
            >
              {{ generating[data.id] ? "Generowanie..." : "Wygeneruj" }}
            </button>
            <a
              download
              :href="visualisationUrl[data.id]"
              v-show="visualisationUrl[data.id]"
            >
              <span>Pobierz</span>
            </a>
          </td>
          <td v-if="adminCategory === 'company'">
            <main-btn
              style="display: inline-block; margin-right: 10px"
              content="Podejrzyj firmę"
              :gray="true"
              :small="true"
              :disabled="!data.editions || data.editions.length === 0"
              @click="activatePreview(data.id)"
            />
          </td>
          <td v-if="usersInCompany">
            <button @click="deleteSection(data.id)">Odepnij użytkownika</button>
          </td>
          <td v-else>
            <button @click="editSection(data)">Edytuj</button>
            <button class="danger-text" @click="deleteSection(data.id)">
              Usuń
            </button>
          </td>
          <div v-if="deleteWarning" class="delete-warning">
            <div v-if="usersInCompany" class="delete-warning__buttons">
              <p>Czy na pewno chcesz odpiąć użytkownika {{ data.name }}?</p>
              <button @click="removeUserConfirm(true)">Tak</button>
              <button @click="removeUserConfirm(false)">Nie</button>
            </div>
            <div v-else class="delete-warning__buttons">
              <p>Czy na pewno chcesz usunąć?</p>
              <button @click="deleteConfirm(true)">Tak</button>
              <button @click="deleteConfirm(false)">Nie</button>
            </div>
          </div>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
/* tslint:disable */
import Vue from "vue";
import { HTTP } from "@/utils/http-common";
import { mapGetters } from "vuex";

export default Vue.extend({
  /* tslint:disable */
  props: {
    headers: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
    adminCategory: {
      type: String,
      required: true,
      default: "",
    },
    usersInCompany: {
      default: false,
    },
    searchItem: {
      type: String,
      required: true,
      default: "title",
    },
    itemToAdd: {
      type: [Object, String],
    },
    itemToEdit: {
      type: [Object, String],
    },
  },
  data() {
    return {
      generating: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false,
        13: false,
      },
      searched: "",
      deleteWarning: false,
      itemToRemove: "",
      editAvailable: false,
      excelUrl: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: "",
        13: "",
      },
      advertUrl: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: "",
        13: "",
      },
      filesUrl: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: "",
        13: "",
      },
      visualisationUrl: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: "",
        13: "",
      },
    };
  },
  computed: {
    ...mapGetters(["adminContentAdd", "adminContentEdit"]),
  },
  watch: {
    tableData() {
      this.searched = this.tableData;
    },
    adminContentAdd() {
      this.tableData.unshift(this.adminContentAdd);
    },
    adminContentEdit() {
      for (let i = 0; i < this.searched.length; i++) {
        if (this.searched[i].id === this.adminContentEdit.id) {
          this.searched.splice(i, 1);
          this.searched[i] = this.adminContentEdit;
        }
      }
    },
  },
  methods: {
    returnObjectValue(data, index) {
      return data[Object.keys(data)[index]];
    },
    search(e) {
      const re = new RegExp(e, "i");
      this.searched = this.tableData.filter((item) => {
        if (item[this.searchItem].match(re)) {
          return item;
        }
      });
    },
    deleteSection(id) {
      this.deleteWarning = true;
      this.itemToRemove = id;
    },
    deleteConfirm(confirm) {
      if (confirm) {
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].id === this.itemToRemove) {
            this.tableData.splice(i, 1);
          }
        }
        HTTP.delete(`api/${this.adminCategory}/${this.itemToRemove}`);
      }
      this.deleteWarning = false;
    },
    editSection(content) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      if (this.adminCategory === "company") {
        this.$router.push(`/admin/firmy/${content.id}`);
      }
      this.$emit("start-editing", content);
    },
    removeUserConfirm(confirm) {
      if (confirm) {
        const companyId = this.$router.history.current.params.id;
        HTTP.delete(`api/company/user/${companyId}/${this.itemToRemove}`).then(
          (response) => {
            for (let i = 0; i < this.tableData.length; i++) {
              if (this.tableData[i].id === this.itemToRemove) {
                this.tableData.splice(i, 1);
              }
            }
          }
        );
      }
      this.deleteWarning = false;
    },
    downloadExcel(edition) {
      HTTP.get(`api/edition/export/${edition}`)
        .then((response) => {
          this.excelUrl[edition] = response.data.data.message;
          this.handlePassword(response.data.data?.password);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    downloadAdvertisements(edition) {
      HTTP.get(`api/edition/exportAdvertisement/${edition}`)
        .then((response) => {
          this.advertUrl[edition] = response.data.data.message;
          this.handlePassword(response.data.data?.password);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    downloadFiles(edition) {
      HTTP.get(`api/edition/exportFiles/${edition}`)
        .then((response) => {
          this.filesUrl[edition] = response.data.data.message;
          this.handlePassword(response.data.data?.password);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    downloadVisualisation(edition) {
      this.generating[edition] = true;
      HTTP.get(`api/edition/exportVisualisations/${edition}`)
        .then((response) => {
          this.generating[edition] = false;
          this.visualisationUrl[edition] = response.data.data.message;
        })
        .catch((error) => {
          this.generating[edition] = false;
          console.error(error);
        });
    },
    activatePreview(id) {
      this.$store.dispatch("getCompanyData", id).then((response) => {
        this.$router.push("/informacje-organizacyjne");
      });
    },
    handlePassword(password = null) {
      if (password) {
        alert(`Hasło do wygenerowanego pliku: ${password}`);
      }
    },
  },
  mounted() {
    this.searched = this.tableData;
  },
});
</script>

<style lang="scss" scoped>
.table-d {
  border-spacing: 0px;
  border-collapse: collapse;
  color: $dark-gray-color;
  width: 100%;
  overflow-x: visible;
  text-align: left;
  margin-top: 30px;

  @include desktop {
    overflow-x: hidden;
  }

  &__thead {
    background-color: $light-gray-color;

    th {
      padding: 25px;
      font-size: 20px;
      font-weight: 300;
    }
  }

  &__tbody {
    tr {
      border-bottom: 1px solid $light-gray-color;
    }

    td {
      padding: 25px;
      max-width: 400px;
      word-wrap: break-word;
    }
  }
}
.list-padding {
  padding-left: 20px;
}
.delete-warning {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: $primary-color;
  padding: 10px 30px;
  color: #fff;

  &__buttons {
    text-align: center;

    p {
      display: block;
    }
  }
}
</style>
